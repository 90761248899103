/* eslint-disable camelcase */
import _ from 'lodash'
import moment from 'moment'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      track: 0,
    }
  },
  computed: {
    ...mapState('conversation', ['conversationInformation', 'conversations']),
    ...mapState('layouts', ['notification_sound']),
    ...mapState('inbox', [
      'roomLists',
      'roomFilter',
      'searchRoomList',
      'filterParams',
      'inboxTabs',
      'unreadCount',
      'inboxPooling',
    ]),
    ...mapState('organization', ['permissions']),
    inboxPoolingConfig() {
      return this.inboxPooling?.defaultConfig
    },
  },
  methods: {
    fcmIncomingHandler(payload) {
      if (payload.data.event_type && payload.data.event_type === 'calls') {
        // eslint-disable-next-line no-console
        console.log('[Calls][fcmIncomingHandler] payload', payload)
      }
      if (payload.data.type === 'calls') {
        // eslint-disable-next-line no-console
        console.log('[Text][fcmIncomingHandler] payload', payload)
      }
      const senderType = payload.data.sender_type
      const eventType = payload.data.event_type

      if (eventType === 'calls') {
        // handling event for wa call
        this.onHandleCallEvent(payload.data)
      }

      if (
        senderType === 'Models::Contact' ||
        senderType === 'Models::User' ||
        senderType === 'message' ||
        senderType === 'Models::SystemAccount'
      ) {
        // Parse data to JSON
        if (typeof payload.data.sender !== 'object')
          payload.data.sender = JSON.parse(payload.data.sender)
        if (typeof payload.data.room !== 'object')
          payload.data.room = JSON.parse(payload.data.room)

        // Display notification
        // this.$store.commit('layouts/DISPLAY_MESSAGE_NOTIFICATION', {
        //   message: payload.data.type === 'text' ? payload.data.text : `Sent you ${payload.data.type}`,
        //   channel: payload.data.room.channel,
        //   sender: payload.data.sender.name,
        //   room_id: payload.data.room.id
        // })
      }

      if (
        payload.data.type === 'location' &&
        payload.data.extra &&
        typeof payload.data.extra !== 'object'
      ) {
        payload.data.extra = JSON.parse(payload.data.extra)
      }

      if (
        senderType === 'Models::User' ||
        senderType === 'message' ||
        senderType === 'Models::SystemAccount'
      ) {
        // Get formatted data for incoming message notification
        this.incomingFcmFormatter(payload.data)
      } else if (senderType === 'Models::Contact' || senderType === 'bot') {
        // sendDataToDatadog
        this.samplerDatadog(payload)

        // Show Manual Desktop Notification with Library
        // this.showManualDesktopNotification(payload.data)

        // Handling desktop notification sound
        this.notificationSoundHandler(payload.data)

        // If received from reply message
        this.receiveMessageHandler(payload.data)

        // Update status of the room
        this.$store.commit('inbox/UPDATE_ASSIGNATION_ROOM', {
          room: payload.data.room_id,
          sender: this.$auth.user,
        })

        // Update last_seen
        if (payload.data.room_id === this.conversationInformation.id)
          this.$store.commit(
            'conversation/UPDATE_CONVERSATION_LAST_SEEN',
            payload.data.room.session_at
          )
        if (this.permissions.message_notification_ack) {
          const messageId = payload.data.id
            ? payload.data.id
            : payload.data.data.id
          this.trackNotificationLog(messageId)
        }
      } else if (senderType === 'agent_notification') {
        this.receiveAgentHandler(payload.data)
      } else if (
        eventType === 'add_agent' ||
        eventType === 'system_auto_assign_room'
      ) {
        this.receiveAddAgentHandler(payload.data)
      } else if (eventType === 'remove_agent') {
        this.receiveRemoveAgentHandler(payload.data)
      } else if (eventType === 'resolve_room') {
        this.receiveResolveHandler(payload.data)
      } else if (eventType === 'send_webhook') {
        this.receiveMessageStatusHandler(payload.data)
      } else if (eventType === 'agent_take_room') {
        this.receiveAgentNewChat(payload.data)
      } else if (eventType === 'room_taken') {
        this.receiveRoomTaken(payload.data)
      } else if (eventType === 'user_online_status') {
        // handle user online status notification
        this.receiveOnlineStatus(payload.data)
      } else if (eventType === 'new_room_created') {
        this.handleRoomWhenCreated(payload.data)
      } else if (eventType === 'read_messages') {
        this.handleReadMessages(payload.data)
      } else if (eventType === 'agent_handover_room') {
        this.handleHandoverAgent(payload.data)
      } else if (eventType === 'update_auto_resolve') {
        this.getCredential()
      }

      this.resetPoolingCheckpoint()
    },
    onHandleCallEvent(data) {
      if (!data.session) {
        this.$store.commit(
          'call/UPDATE_CALL_WIDGET',
          {
            ...data,
          },
          {
            root: true,
          }
        )
        return
      }
      const sessionObject = this.onHandleSdpAnswer(data.session)
      this.$store.commit(
        'call/UPDATE_CALL_WIDGET',
        {
          ...data,
          session: {
            ...sessionObject,
          },
        },
        {
          root: true,
        }
      )
    },
    onHandleSdpAnswer(session) {
      try {
        if (!session) throw new Error('Session sdp is required')

        let sessionObject = null
        if (typeof session === 'object') {
          sessionObject = session
        }
        if (typeof session === 'string') {
          const sanitizedSession = session
            .replace(/\r/g, '\\r')
            .replace(/\n/g, '\\n')
          sessionObject = JSON.parse(sanitizedSession)
        }
        return sessionObject
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('onHandleSdpAnswer:', err)
      }
    },
    sendDataToDatadog(data) {
      try {
        const histogram = process.env.DD_HISTOGRAM
        if (
          histogram === true ||
          histogram === 'true' ||
          histogram === 'TRUE'
        ) {
          const dataCreated_at = new Date(data.data.created_at)
          const timeNow = new Date()
          const notificationLag = (timeNow - dataCreated_at) / 1000
          const payload = {
            created_at: data.data.created_at,
            time_now: timeNow,
            notification_lag: notificationLag,
          }
          this.$axios.post('web/api/metrics', payload)
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      }
    },
    samplerDatadog(data) {
      if (this.permissions?.dd_histogram) {
        const sampleRate = this.permissions.dd_histogram_sample_rate
        return this.samplerSendDatadog(sampleRate, data)
      }
    },
    samplerSendDatadog(count = 1, data) {
      this.track++
      if (this.track === count) {
        this.sendDataToDatadog(data)
        this.track = 0
      }
    },
    fcmGetParticipantLists() {
      this.$store.dispatch('conversation/getParticipantLists', {
        id: this.$route.query.room,
        type: 'agent',
      })
    },
    fcmGetAgentAssignLists() {
      this.$store.dispatch('conversation/getAgentAssignLists', {
        room_id: this.$route.query.room,
        payload: {
          offset: 1,
          limit: 10,
        },
      })
    },
    agentFormatter(data) {
      const agentName = data.data.agent_name
        ? data.data.agent_name
        : data.body.substring(
            data.body.indexOf('>') + 1,
            data.body.lastIndexOf('<')
          )
      const index = _.findIndex(this.conversationInformation.agents, {
        profile: { name: agentName },
      })
      const room = data.data.extra.room
      room.last_message = {
        created_at: moment().format(),
        text: data.body,
        type: 'system',
        extra: data.extra || '',
      }
      room.last_message_at = moment().format()
      data = {
        created_at: moment().format(),
        external_id: null,
        id: data.id,
        agent_name: agentName,
        agent_id:
          index > -1
            ? this.conversationInformation.agents[index].id
            : data.data.agent_id,
        agent_contact_able_id:
          typeof data.data.extra.kicked_agent_id !== 'undefined'
            ? data.data.extra.kicked_agent_id
            : null,
        message: JSON.stringify({
          document: {
            filename: null,
            filetype: null,
            size: null,
            url: null,
          },
          image: {
            filename: null,
            filetype: null,
            large: null,
            medium: null,
            size: null,
            small: null,
          },
          video: {
            large: null,
            medium: null,
            small: null,
            filename: null,
            filetype: null,
            size: null,
            url: null,
          },
          location: {
            url: null,
            name: null,
            lat: null,
            long: null,
            address: null,
          },
          text: data.body,
          type: 'system',
        }),
        participant_type: 'system',
        room_id: data.data.extra.room.id,
        room: data.data.extra.room,
        send_type: 'message',
        sender: JSON.stringify({
          avatar: null,
          id: data.data?.actor_id,
          name: data.data?.actor_name ? data.data.actor_name : 'Someone',
          type: data.type,
        }),
        status: 'created',
        text: data.body,
        type: 'system',
      }

      return data
    },
    incomingFcmRoomFormatter(data) {
      const room = data.data.extra.room
      room.last_message = {
        created_at: moment().format(),
        text: data.body,
        type: 'system',
        extra: data.extra || '',
      }
      room.last_message_at = moment().format()

      data = {
        created_at: moment().format(),
        external_id: null,
        id: data.data.extra.room.id,
        message: JSON.stringify({
          document: {
            filename: null,
            filetype: null,
            size: null,
            url: null,
          },
          image: {
            filename: null,
            filetype: null,
            large: null,
            medium: null,
            size: null,
            small: null,
          },
          video: {
            large: null,
            medium: null,
            small: null,
            filename: null,
            filetype: null,
            size: null,
            url: null,
          },
          location: {
            url: null,
            name: null,
            lat: null,
            long: null,
            address: null,
          },
          text: data.body,
          type: 'system',
        }),
        participant_type: 'system',
        room_id: data.data.extra.room.id,
        room: data.data.extra.room,
        send_type: 'message',
        sender: JSON.stringify({
          avatar: null,
          id: null,
          name: null,
          type: data.type,
        }),
        status: 'created',
        text: data.body,
        type: 'system',
        unread_count: data.data.extra.room.unread_count,
      }

      return data
    },
    async incomingFcmFormatter(data) {
      const TEXT = data.text
      if (typeof data.room === 'string') data.room = JSON.parse(data.room)
      if (typeof data.reply === 'string') data.reply = JSON.parse(data.reply)
      if (typeof data.file === 'string') data.file = JSON.parse(data.file)
      if (typeof data.extra === 'string') data.extra = JSON.parse(data.extra)
      data.is_campaign = (data.is_campaign || '').toLowerCase() === 'true'

      if (data.is_partial === 'true' || data.is_partial === true) {
        const messageDetail = await this.getMessageIfPartial(data)
        if (messageDetail) {
          data = messageDetail
        }
      }

      data.room.last_message_at = data.created_at
      data.room.last_message = {
        created_at: data.created_at,
        type: data.type,
        text: data.text,
        participant_type: data.participant_type,
        extra: data.extra || '',
      }

      data.room.unread_count = data.room.unread_count || 0
      data.sender_type = 'message'
      data.status = 'delivered'
      data.sender = JSON.stringify({
        avatar: data.sender?.avatar?.large?.url
          ? data.sender.avatar.large.url
          : null,
        id: data.sender_id,
        name: data.sender?.name ? data.sender.name : 'User', // Setidaknya tidak kosong name nya, ditambahkan "User" sbg default
      })

      const file = data.file || { filename: '', url: null, size: null }

      let filetype = []

      if (data.type === 'document' || data.type === 'image') {
        filetype = file.filename.split('.')
      }

      let attachments = []

      if (data.file) {
        attachments.push(data.file)
      } else {
        attachments = data.attachments
      }

      data.message = JSON.stringify({
        type: data.type,
        text: data.text,
        image: {
          large: data.type === 'image' ? file.url : null,
          medium: data.type === 'image' ? file.large?.url : null,
          small: data.type === 'image' ? file.large?.url : null,
          filename: data.type === 'image' ? file.filename : null,
          filetype:
            data.type === 'image' ? filetype[filetype.length - 1] : null,
          size: data.type === 'image' ? (file.size / 1048576).toFixed(2) : null,
        },
        document: {
          url: data.type === 'document' ? file.url : null,
          filename: data.type === 'document' ? file.filename : null,
          filetype:
            data.type === 'document' ? filetype[filetype.length - 1] : null,
          size:
            data.type === 'document' ? (file.size / 1048576).toFixed(2) : null,
        },
        video: {
          large: data.type === 'video' && file.url ? file.url : null,
          medium: data.type === 'video' && file.large ? file.large.url : null,
          small: data.type === 'video' && file.large ? file.large.url : null,
          filename: data.type === 'video' ? file.filename : null,
          filetype:
            data.type === 'video' ? filetype[filetype.length - 1] : null,
          size: data.type === 'video' ? (file.size / 1048576).toFixed(2) : null,
          url: data.type === 'video' ? file.url : null,
        },
        location: {
          url: data.extra ? data.extra.url : null,
          name: data.extra ? data.extra.name : null,
          lat: data.extra ? data.extra.latitude : null,
          long: data.extra ? data.extra.longitude : null,
          address: data.extra ? data.extra.address : null,
        },
        voice: {
          url:
            data.type === 'voice' || (data.type === 'audio' && file.url)
              ? file.url
              : null,
          filename:
            data.type === 'voice' || data.type === 'audio'
              ? file.filename
              : null,
          size:
            data.type === 'voice' || data.type === 'audio'
              ? (file.size / 1048576).toFixed(2)
              : null,
        },
        email: {
          attachments,
          attachments_type:
            this.conversationInformation.channel === 'email' && data.attachments
              ? data.type
              : null,
          cc: this.conversationInformation.channel === 'email' ? data.cc : null,
          bcc:
            this.conversationInformation.channel === 'email' ? data.bcc : null,
          reply_to:
            this.conversationInformation.channel === 'email'
              ? data.reply_to
              : null,
        },
        story: {
          url: data.type === 'story' ? data.url : null,
        },
        file: data.file,
      })
      data.text = TEXT

      if (data.type !== 'system') data.type = 'message'
      // Run incoming message handler
      this.sendingMessageHandler(data)
    },
    async sendingMessageHandler(data) {
      // Parsing to JSON
      data.message =
        typeof data.message === 'string'
          ? JSON.parse(data.message)
          : data.message
      data.sender =
        typeof data.sender === 'string' ? JSON.parse(data.sender) : data.sender
      data.extra =
        typeof data.extra === 'string' ? JSON.parse(data.extra) : data.extra

      let attachments = []

      if (data.file) {
        data.file =
          typeof data.file === 'string' ? JSON.parse(data.file) : data.file
        attachments.push(data.file ? data.file : [])
      } else if (data.attachments) {
        data.attachments =
          typeof data.attachments === 'string'
            ? JSON.parse(data.attachments)
            : data.attachments
        const fileList = []
        data.attachments.forEach((list) => {
          fileList.push(list.file)
        })
        attachments = fileList
      }

      data.message.email =
        this.conversationInformation.channel === 'email'
          ? { attachments, attachments_type: data.room.last_message.type }
          : null

      // Update to conversation if on the same room
      if (this.$route.name === 'inbox') {
        if (this.$route.query) {
          if (this.$route.query.room === data.room_id) {
            if (
              data.room.call_permission_request &&
              data.room.call_permission_request.length > 0
            ) {
              // update room detail
              await this.$store.commit(
                'inbox/UPDATE_ROOM_DETAIL',
                {
                  call_permission_request: data.room.call_permission_request,
                },
                {
                  root: true,
                }
              )
            }

            // handle no `delivered` status on instagram, telegram, livechat_dot_com, line, & twitter channel
            const noDeliveredChannel =
              data.room.channel === 'ig' ||
              data.room.channel === 'telegram' ||
              data.room.channel === 'livechat_dot_com' ||
              data.room.channel === 'line' ||
              data.room.channel === 'twitter'
            if (noDeliveredChannel && data.status === 'delivered') {
              await this.$store.commit('conversation/PUSH_CONVERSATION', {
                ...data,
                status: 'sent',
              })
            } else {
              await this.$store.commit('conversation/PUSH_CONVERSATION', data)
            }
          }
        }
      }

      const validatePushToTabs = () => {
        const channel = data.room.channel
        const activeTab = this.inboxTabs ? this.inboxTabs : 'messages'
        const filterTabs = {
          comments: ['ig_comment', 'google_my_business'],
          messages: [
            'app_chat',
            'tokopedia_chat',
            'shopee',
            'web_chat',
            'wa_cloud',
            'fb',
            'ig',
            'email',
            'telegram',
            'twitter',
            'line',
            'livechat_dot_com',
            'qontak',
            'qiscus',
            'wa',
            'meta_conversion',
            'meta_ad_account',
          ],
        }
        const isOnFilterTabs = filterTabs[activeTab].includes(channel)
        updateUnreadCount()
        if (isOnFilterTabs) {
          unshiftLiftUpRoom()
        }
        // if (this.inboxTabs === 'comments' && channel === 'ig_comment') {
        //   // push room if in tab comments
        //   unshiftLiftUpRoom()
        // } else if (this.inboxTabs !== 'comments' && channel !== 'ig_comment') {
        //   // push room if in tab message
        //   unshiftLiftUpRoom()
        // }
      }

      const updateUnreadCount = () => {
        const channel = data.room.channel
        if (
          data.participant_type !== 'agent' &&
          data.participant_type !== 'bot' &&
          data.type === 'message'
        ) {
          this.$store.commit('inbox/UPDATE_UNREAD_COUNT', {
            type: channel === 'ig_comment' ? 'comment' : 'customer_service',
          })
        }
        if (data.participant_type === 'agent') {
          this.$store.dispatch('inbox/getUnreadCount')
        }
      }

      const unshiftLiftUpRoom = async () => {
        if (this.filterParams.response_status.length < 1) {
          const index = _.findIndex(this.roomLists, { id: data.room_id })

          if (index < 0) {
            await this.$store.commit('inbox/UPDATE_UNSHIFT_ROOM', {
              room: data.room,
            })
          } else {
            const existingRoom = this.roomLists[index] || {}
            const newRoom = data.room || {}
            if (
              moment(newRoom.last_activity_at).isSameOrAfter(
                moment(existingRoom.last_activity_at)
              )
            ) {
              // Update to room list
              await this.$store.commit('inbox/UPDATE_LIFT_UP_ROOM', {
                index,
                room: newRoom,
                flagNewRoomOrderingLogic:
                  this.permissions.new_room_ordering_logic,
              })
            }
          }
        } else if (this.filterParams.response_status.length > 0) {
          if (
            this.checkRoomUnresponded(
              this.conversationInformation.unread,
              this.conversationInformation.status
            )
          ) {
            const index = _.findIndex(this.roomLists, { id: data.room_id })
            if (index < 0) {
              await this.$store.commit('inbox/UPDATE_UNSHIFT_ROOM', {
                room: data.room,
              })
            } else {
              // Update to room list
              await this.$store.commit('inbox/UPDATE_LIFT_UP_ROOM', {
                index,
                room: data.room,
                flagNewRoomOrderingLogic:
                  this.permissions.new_room_ordering_logic,
              })
            }
          } else {
            const index = _.findIndex(this.roomLists, { id: data.room_id })
            if (index > -1)
              this.$store.commit('inbox/REMOVE_ROOM', { index }, { root: true })
          }
        }
      }
      validatePushToTabs()

      // Update SLA inbox
      const roomId = this.conversationInformation.id || this.$route.query.room
      if (!this.conversationInformation.first_response_at && roomId) {
        this.$store.dispatch('conversation/getInboxSLAByActor', {
          room_id: roomId,
        })
      }
      const isNotHaveSLA = ['wa_group', 'google_my_business', 'ig_comment']
      if (
        data.room?.status === 'assigned' &&
        !isNotHaveSLA.includes(data.room.channel)
      )
        this.$store.dispatch('inbox/getRoomSLA', { ids: [roomId] })
    },
    async receiveMessageStatusHandler(data) {
      data.data =
        typeof data.data === 'object' ? data.data : JSON.parse(data.data)
      if (this.$route.name === 'inbox') {
        if (this.$route.query) {
          if (this.$route.query.room === data.data.extra.room_id) {
            for (let i = 0; i < data.data.extra.messages.length; i++) {
              const index = _.findIndex(this.conversations, {
                id: data.data.extra.messages[i].id,
              })
              if (index > -1) {
                await this.$store.commit('conversation/UPDATE_MESSAGE_STATUS', {
                  index,
                  status: data.data.extra.messages[i].status,
                })
              }
            }
          }
        }
      }
    },
    async receiveMessageHandler(data) {
      // Receive current data
      let room =
        typeof data.room === 'string' ? JSON.parse(data.room) : data.room
      let file = data.file || null
      if (data.file && typeof data.file === 'string') {
        file = JSON.parse(data.file)
      }

      let extra = data.extra || {}
      if (data.extra && typeof data.extra === 'string') {
        extra = JSON.parse(data.extra)
      }

      let reply = data.reply || null
      if (data.reply && typeof data.reply === 'string') {
        reply = JSON.parse(data.reply)
      }

      data.is_campaign = (data.is_campaign || '').toLowerCase() === 'true'

      if (data.is_partial === 'true' || data.is_partial === true) {
        const messageDetail = await this.getMessageIfPartial(data)
        if (messageDetail) {
          data = messageDetail
          room = messageDetail.room
          file = messageDetail.file
        }
      }

      let filetype = []

      if (data.type === 'document' || data.type === 'image') {
        filetype = file.filename.split('.')
      }

      room.last_message_at = data.created_at
      room.last_message = {
        created_at: data.created_at,
        type: data.type,
        text: data.text,
        extra: data.extra || {},
      }

      let attachments = []

      if (data.file?.filename) {
        attachments.push(data.file)
      } else {
        attachments = data.attachments
      }

      const dataToSend = {
        created_at: data.created_at,
        id: data.id,
        attachments,
        message: JSON.stringify({
          type: data.type,
          text: data.text,
          image: {
            large: data.type === 'image' ? file.url : null,
            medium: data.type === 'image' ? file.large.url : null,
            small: data.type === 'image' ? file.large.url : null,
            filename: data.type === 'image' ? file.filename : null,
            filetype:
              data.type === 'image' ? filetype[filetype.length - 1] : null,
            size:
              data.type === 'image' ? (file.size / 1048576).toFixed(2) : null,
          },
          document: {
            url: data.type === 'document' ? file.url : null,
            filename: data.type === 'document' ? file.filename : null,
            filetype:
              data.type === 'document' ? filetype[filetype.length - 1] : null,
            size:
              data.type === 'document'
                ? (file.size / 1048576).toFixed(2)
                : null,
          },
          video: {
            large: data.type === 'video' && file.url ? file.url : null,
            medium: data.type === 'video' && file.large ? file.large.url : null,
            small: data.type === 'video' && file.large ? file.large.url : null,
            filename: data.type === 'video' ? file.filename : null,
            filetype:
              data.type === 'video' ? filetype[filetype.length - 1] : null,
            size:
              data.type === 'video' ? (file.size / 1048576).toFixed(2) : null,
            url: data.type === 'video' ? file.url : null,
          },
          location: {
            url: extra.url || null,
            name: extra.name || null,
            lat: extra.latitude || null,
            long: extra.longitude || null,
            address: extra.address || null,
          },
          voice: {
            url:
              (data.type === 'voice' || data.type === 'audio') && file.url
                ? file.url
                : null,
            filename:
              data.type === 'voice' || data.type === 'audio'
                ? file.filename
                : null,
            size:
              data.type === 'voice' || data.type === 'audio'
                ? (file.size / 1048576).toFixed(2)
                : null,
          },
          email: {
            attachments,
            attachments_type:
              this.conversationInformation.channel === 'email' &&
              data.attachments
                ? data.type
                : null,
            cc:
              this.conversationInformation.channel === 'email' ? data.cc : null,
            bcc:
              this.conversationInformation.channel === 'email'
                ? data.bcc
                : null,
            reply_to:
              this.conversationInformation.channel === 'email'
                ? data.reply_to
                : null,
          },
          story: {
            url: data.type === 'story' ? data.url : null,
          },
        }),
        extra,
        room_id: data.room_id,
        room,
        participant_type: data.participant_type,
        send_type: 'message',
        sender: JSON.stringify({
          avatar: this.conversationInformation.avatar
            ? this.conversationInformation.avatar
            : null,
          id: data.sender_id,
          name: data.sender.name,
          username: data.sender.username,
        }),
        status: 'delivered',
        type: 'message',
        text: data.text,
        reply,
        file,
      }

      // Still same as receiving sent message method
      this.sendingMessageHandler({
        ...dataToSend,
      })

      // Call unassigned API when met specific condition
      if (room.status === 'unassigned')
        this.$store.dispatch('inbox/getRoomSpecificInfo', {
          target: 'unassigned',
        })

      // Update Unassigned Chat
      // this.$store.commit('inbox/UPDATE_UNASSIGNED_CHAT', 'add')

      // Update to Notification Bell
      this.updateToNotificationBell(room, dataToSend)

      // Update latest floating button
      this.$store.commit('inbox/UPDATE_IS_LATEST', 1)

      if (data.room_id === this.$route.query.room) {
        this.$store.commit(
          'conversation/UPDATE_UNREAD_IN_ROOM',
          room.unread_count
        )
      }
    },
    receiveAgentHandler(data) {
      // Parsing to JSON
      if (typeof data.room === 'string') {
        data.room = JSON.parse(data.room)
      }
      if (typeof data.sender === 'string') {
        data.sender = JSON.parse(data.sender)
      }
      if (typeof data.agent === 'string') {
        data.agent = JSON.parse(data.agent)
      }
      data.type = 'create'

      // Update to conversation if on the same room
      if (this.$route.name === 'inbox') {
        if (this.$route.query) {
          if (this.$route.query.room === data.room.id) {
            // If received from agent assign action
            this.receiveAgentAssignInfoHandler(data)
            this.receiveAgentAssignConvHandler(data)
          }
        }
      }

      this.receiveAgentAssignNotificationHandler(data)
    },
    receiveRemoveAgentHandler(data) {
      data.data =
        typeof data.data === 'object' ? data.data : JSON.parse(data.data)
      const dataFormatted = this.agentFormatter(data)
      const room = {
        id: dataFormatted.room_id,
        name: dataFormatted.text.substring(
          dataFormatted.text.lastIndexOf('room') + 5,
          dataFormatted.text.length
        ),
      }

      // Update to conversation if on the same room
      if (this.$route.name === 'inbox') {
        if (this.$route.query) {
          if (this.$route.query.room === dataFormatted.room_id) {
            // this.sendingMessageHandler(dataFormatted)
            if (
              this.$auth.user.id === dataFormatted.agent_contact_able_id &&
              this.$auth.user.role === 'agent'
            ) {
              this.$store.commit('conversation/REMOVE_AGENT', {
                id: dataFormatted.agent_id,
              })
              this.$store.commit(
                'layouts/UPDATE_NOTIFICATION',
                {
                  display: true,
                  type: 'failed',
                  message: 'Warning!',
                  item: 'You have been removed from this room',
                  closeable: true,
                  callback: {
                    text: 'OK',
                    method: 'redirectToInbox',
                  },
                },
                { root: true }
              )
              this.$store.commit('layouts/UPDATE_NOTIFICATION_CLICKABLE', {
                type: 'inbox',
                room: { id: dataFormatted.room_id },
              })
            } else {
              this.$store.commit('conversation/REMOVE_AGENT', {
                id: dataFormatted.agent_id,
              })
              setTimeout(() => {
                this.fcmGetAgentAssignLists()
              }, 1000)
            }
          } else {
            const index = _.findIndex(this.roomLists, {
              id: dataFormatted.room_id,
            })
            if (this.$auth.user.id === dataFormatted.agent_contact_able_id) {
              if (index > -1) {
                this.$store.commit(
                  'inbox/REMOVE_ROOM',
                  { index },
                  { root: true }
                )
              }
              this.$store.commit('layouts/UPDATE_NOTIFICATION_CLICKABLE', {
                type: 'inbox',
                room: { id: dataFormatted.room_id },
              })
            } else {
              // this.sendingMessageHandler(dataFormatted)
            }
          }
        }
      }

      if (this.$auth.user.id !== dataFormatted.agent_contact_able_id) {
        this.updateToNotificationBell(room, dataFormatted)
      }

      this.$store.commit('inbox/UPDATE_ROOM_STATUS_AFTER_AGENT_ACTION', {
        id: dataFormatted.room_id,
        status: dataFormatted?.room?.status,
      })
      if (!this.conversationInformation.agents.length)
        this.$store.dispatch('inbox/getRoomSpecificInfo', {
          target: 'unassigned',
        })
      const isNotHaveSLA = ['wa_group', 'google_my_business', 'ig_comment']
      if (!isNotHaveSLA.includes(dataFormatted.room.channel)) {
        this.$store.dispatch('inbox/getRoomSLA', {
          ids: [data?.data?.extra?.room?.id],
        })
      }
    },
    receiveAddAgentHandler(data) {
      if (typeof data?.data !== 'object') {
        data.data = JSON.parse(data.data)
      }
      const dataFormatted = this.agentFormatter(data)
      dataFormatted.sender =
        typeof dataFormatted.sender === 'string'
          ? JSON.parse(dataFormatted.sender)
          : dataFormatted.sender

      if (this.$route.name === 'inbox') {
        if (this.$route.query.room) {
          if (this.$route.query.room === dataFormatted.room_id) {
            this.fcmGetParticipantLists()
            setTimeout(() => {
              this.fcmGetAgentAssignLists()
            }, 1000)
          }
        }
        // this.sendingMessageHandler(dataFormatted)
      }
      if (this.conversationInformation.agents.length < 1)
        this.$store.dispatch('inbox/getRoomSpecificInfo', {
          target: 'unassigned',
        })

      if (dataFormatted.agent_id === this.$auth.user.id)
        this.$store.commit('layouts/UPDATE_NOTIFICATION_BELL', {
          id: `notification-add-agent-${moment().format('DDMMYYYYHHmmSSSSSS')}`,
          sender: {
            id: dataFormatted.sender?.id,
            name: dataFormatted.sender?.name,
          },
          receiver: {
            id: dataFormatted.agent_id,
            name: dataFormatted.agent_name,
          },
          room: {
            id: dataFormatted.room?.id,
            name: dataFormatted.room?.name,
          },
          created_at: moment(),
          message: {
            type: 'text',
            text: `${dataFormatted.sender?.name} assigned you ${
              dataFormatted.room?.name
            } on ${this.getChannelName(dataFormatted.room?.channel)}`,
          },
          clickable: true,
          read: false,
          notification_type: 'inbox',
          message_type: 'add_agent',
        })

      // UPDATE TOTAL UNREAD COUNT TABS
      const unread = data?.data?.extra?.room?.unread_count
      const channel = data?.data?.extra?.room?.channel
      this.updateTotalUnreadCountTabs(unread, channel)
      const isNotHaveSLA = ['wa_group', 'google_my_business', 'ig_comment']
      if (!isNotHaveSLA.includes(dataFormatted.room.channel)) {
        this.$store.dispatch('inbox/getRoomSLA', {
          ids: [data?.data?.extra?.room?.id],
        })
      }
    },
    async receiveResolveHandler(data) {
      // Parsing to JSON
      data.data =
        typeof data.data === 'object' ? data.data : JSON.parse(data.data)
      const dataFormatted = this.incomingFcmRoomFormatter(data)
      const room = {
        id: dataFormatted.room_id,
        name: data.data.extra.room.name,
      }

      // Update to conversation if on the same room
      if (this.$route.name === 'inbox') {
        // await this.$store.dispatch('inbox/getRoomSpecificInfo', { target: 'unassigned' })
        if (this.$route.query.room) {
          if (
            this.$route.query.room === dataFormatted.room_id &&
            !this.conversationInformation.resolved
          ) {
            this.$store.commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Warning!',
                item: 'Room has been resolved',
                closeable: true,
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )

            await this.$store.commit(
              'conversation/EDIT_CONVERSATION_INFORMATION',
              {
                type: 'status',
                value: {
                  status: 'resolved',
                },
              }
            )

            await this.$store.commit(
              'conversation/EDIT_CONVERSATION_INFORMATION',
              {
                type: 'resolved',
                value: {
                  resolved: true,
                },
              }
            )
          }
        }
        // Remove from room list because it is no longer "resolved"
        this.$store.commit(
          'inbox/REMOVE_ROOM_EXCEPT_ON_SAME_FILTER',
          {
            id: room.id,
            filter: 'resolve',
          },
          { root: true }
        )
        dataFormatted.unread_count = 0
        // if (this.roomFilter.conversation === 'resolved' || !this.roomFilter.conversation) this.sendingMessageHandler(dataFormatted)
        // Update SLA inbox
        const roomId = this.conversationInformation.id || this.$route.query.room
        if (roomId)
          this.$store.dispatch('conversation/getInboxSLAByActor', {
            room_id: this.conversationInformation.id || this.$route.query.room,
          })
        const isNotHaveSLA = ['wa_group', 'google_my_business', 'ig_comment']
        if (
          data.room?.status === 'assigned' &&
          !isNotHaveSLA.includes(data.room.channel)
        )
          this.$store.dispatch('inbox/getRoomSLA', { ids: [roomId] })
      }

      this.updateToNotificationBell(room, dataFormatted)
    },
    showManualDesktopNotification(data) {
      const notificationData = {
        title:
          data.participant_type.charAt(0).toUpperCase() +
          data.participant_type.slice(1) +
          ' - ' +
          data.sender.name,
        options: {
          body: data.type === 'text' ? data.text : data.type.toUpperCase(),
        },
        events: {
          onclick() {
            this.$router.push('/inbox/' + data.room_id)
          },
        },
      }

      // Show desktop notification
      this.$notification.show(
        notificationData.title,
        notificationData.options,
        notificationData.events
      )
    },
    notificationSoundHandler(data) {
      if (this.notification_sound) {
        let ringtone = ''
        if (data.room_id === this.$route.query.room)
          ringtone = document.getElementById('conversation_ringtone')
        else ringtone = document.getElementById('message_ringtone')
        const play = ringtone.play()
        if (!play) {
          // eslint-disable-next-line no-console
          console.info(
            'You need at least interact with one of our page component (eg. find room, click any button)'
          )
        }
      }
    },
    updateToNotificationBell(room, dataToSend) {
      this.$store.commit('layouts/UPDATE_NOTIFICATION_BELL', {
        id: dataToSend.id,
        sender: {
          id: room.id,
          name: room.name,
        },
        room: {
          id: room.id,
          name: room.name,
          channel: room.channel,
          unreadCount: room.unread_count,
        },
        created_at: dataToSend.created_at,
        message:
          typeof dataToSend.message === 'object'
            ? dataToSend.message
            : JSON.parse(dataToSend.message),
        clickable: true,
        read: false,
        notification_type: 'inbox',
      })
    },
    receiveAgentAssignInfoHandler(data) {
      if (data.type === 'create')
        this.$store.commit('conversation/ASSIGN_AGENT', data.agent)
      else this.$store.commit('conversation/REMOVE_AGENT', data.agent)
    },
    receiveAgentAssignConvHandler(data) {
      if (data.type === 'create')
        this.$store.commit(
          'conversation/UPDATE_ASSIGNED_AGENT_NOTIFICATION',
          data
        )
      else
        this.$store.commit(
          'conversation/REMOVE_ASSIGNED_AGENT_NOTIFICATION',
          data
        )
    },
    receiveAgentAssignNotificationHandler(data) {
      if (data.type === 'create') {
        // If sent agent id is same with current id
        if (data.agent.id === this.$auth.user.id) {
          this.$store.commit('layouts/UPDATE_NOTIFICATION_BELL', {
            sender: data.sender,
            created_at: data.created_at,
            message: data.message,
            read: false,
            notification_type: 'others',
            room: data.room,
          })
        }
      }

      // Update to room list
      this.$store.commit('inbox/UPDATE_ROOM', {
        conversationInformation: data.conversationInformation,
        send_type: 'message',
        customMsg: {
          type: 'notification',
          text:
            data.type === 'create'
              ? 'Conversation has been assigned to ' + data.agent.full_name
              : data.agent.full_name +
                ' has been removed from this conversation by ' +
                data.sender.name,
        },
        unreadUpdate: null,
      })
    },
    receiveResolveNotification(data) {
      this.$store.commit('conversation/RESOLVE_CONV', true)
      this.$store.commit('conversation/UPDATE_RESOLVED_NOTIFICATION', data)
    },
    receiveResolveNotificationRoomUpdate(data) {
      this.$store.commit('inbox/UPDATE_ROOM', {
        conversationInformation: data.conversationInformation,
        send_type: 'message',
        customMsg: {
          type: 'notification',
          text: 'Conversation has been resolved by ' + data.agent.full_name,
        },
        unreadUpdate: null,
      })
    },
    receiveAgentNewChat(data) {
      const payload =
        typeof data?.data === 'object' ? data.data : JSON.parse(data.data)

      if (this.$auth.user.role === 'agent') {
        // Store metric to mixpanel
        this.storeMixpanelMetric('Get Chat', {
          Role: this.$auth.user.role,
          'Company ID': this.getOrganizationForMetric()['Company ID'],
          'Company Name': this.getOrganizationForMetric()['Company Name'],
          Channel: this.getChannelName(payload?.extra?.room?.channel ?? ''),
        })
      }

      const agentName = /<b>(.*?)<\/b>/g.exec(data.body)

      payload.extra.room.last_message = {
        type: 'notification',
        text: this.isAgent()
          ? 'You have taken this conversation'
          : agentName
          ? `${agentName[0]} taken this conversation`
          : data.body,
        extra: data.extra || '',
      }

      const index = _.findIndex(this.roomLists, { id: payload.extra.room.id })
      if (index < 0) {
        this.$store.commit('inbox/UPDATE_UNSHIFT_ROOM', {
          room: payload.extra.room,
        })
      } else {
        this.$store.commit('inbox/UPDATE_LIFT_UP_ROOM', {
          index,
          room: payload.extra.room,
        })
      }

      // UDPATE TOTAL UNREAD COUNT TABS
      const unread = payload?.extra?.room?.unread_count
      const channel = payload?.extra?.room?.channel
      this.updateTotalUnreadCountTabs(unread, channel)
      const isNotHaveSLA = ['wa_group', 'google_my_business', 'ig_comment']
      if (!isNotHaveSLA.includes(payload.extra.room.channel)) {
        this.$store.dispatch('inbox/getRoomSLA', {
          ids: [payload.extra.room.id],
        })
      }
    },
    receiveRoomTaken(data) {
      data.meta =
        typeof data.meta === 'string' ? JSON.parse(data.meta) : data.meta
      this.$store.commit(
        'inbox/UPDATE_EXACT_UNASSIGNED_CHAT',
        data.meta.info.total
      )
    },
    receiveOnlineStatus(data) {
      const user = this.$auth.user
      let is_online = data.is_online
      if (is_online === true || is_online === 'true') {
        is_online = true
      } else {
        is_online = false
      }

      if (user.id === data.user_id) {
        const userStatus = {
          is_online,
          status: data.status,
          timestamp: data.timestamp,
        }
        this.$store.commit('layouts/UPDATE_USER_STATUS', userStatus)
      }
    },
    handleRoomWhenCreated(data) {
      /**
       * @TODO
       * Somehow, the count data from FCM is not matched with current user's.
       * The FCM count seems to match the Admin's, not the current user's.
       * So for now, we'll pull the unassigned count manually until it's fixed.
       */
      // this.$store.dispatch('inbox/getRoomSpecificInfo', {
      //   target: 'unassigned',
      // })
      // try to use this instead of above. And if this one is working. Will remove dispatch getRoomSpecificInfo on line 765
      if (data?.meta) {
        const meta =
          data.meta && typeof data.meta === 'object'
            ? data.meta
            : JSON.parse(data.meta ?? '{}')
        this.$store.commit(
          'inbox/UPDATE_EXACT_UNASSIGNED_CHAT',
          meta.info.total
        )
      } else {
        this.$store.dispatch('inbox/getRoomSpecificInfo', {
          target: 'unassigned',
        })
      }
    },
    handleReadMessages(data) {
      const parsedData =
        typeof data?.data === 'object' ? data.data : JSON.parse(data.data)
      const roomId = parsedData.extra.room_id

      // update on roomList
      this.$store.commit('inbox/READ_ROOM', roomId)

      // update on roomDetail
      if (roomId === this.$route.query.room) {
        this.$store.commit('conversation/UPDATE_UNREAD_IN_ROOM', 0)
      }

      if (this.filterParams.response_status.length > 0) {
        this.handleUnrespondedRoom(roomId)
      }
    },
    getCredential() {
      this.$store.dispatch('template/getCredential', {})
    },
    async handleHandoverAgent(data) {
      const parsedData =
        typeof data?.data === 'object' ? data.data : JSON.parse(data.data)
      const roomId = parsedData.extra.room_id

      const roomDetail = await this.$store.dispatch(
        'inbox/getRoomDetail',
        roomId
      )

      if (roomDetail) {
        const roomIdx = _.findIndex(this.roomLists, { id: roomId })

        if (roomIdx > -1) {
          // update room list
          this.$store.commit('inbox/UPDATE_LIFT_UP_ROOM', {
            index: roomIdx,
            room: roomDetail,
          })
        } else {
          // add to room list
          this.$store.commit('inbox/UPDATE_UNSHIFT_ROOM', {
            room: roomDetail,
          })
        }
      }

      if (parsedData.extra?.agent?.id === this.$auth.user.id)
        this.$store.commit('layouts/UPDATE_NOTIFICATION_BELL', {
          id: `notification-handover-${moment().format('DDMMYYYYHHmmSSSSSS')}`,
          sender: {
            id: parsedData.extra?.actor?.id,
            name: parsedData.extra?.actor?.name,
          },
          receiver: {
            id: parsedData.extra?.agent?.id,
            name: parsedData.extra?.agent?.name,
          },
          room: {
            id: parsedData.extra?.room?.id,
            name: parsedData.extra?.room?.name,
          },
          created_at: moment(),
          message: {
            type: 'text',
            text: `${parsedData.extra?.actor?.name} handed over you ${parsedData.extra?.room?.name}`,
          },
          clickable: true,
          read: false,
          notification_type: 'inbox',
          message_type: 'handover',
        })

      const stickyToastMsg = {
        isShow: true,
        message: `<b>${parsedData.extra?.actor?.name}</b> handed you over a message with <b>${parsedData.extra?.room?.name}</b>. You can now reply to that message`,
        iconName: 'bulk-send',
        iconVariant: 'duotone',
        variant: 'info',
        duration: 5000,
      }
      this.$store.commit('layouts/UPDATE_STICKY_TOAST', stickyToastMsg)
      const isNotHaveSLA = ['wa_group', 'google_my_business', 'ig_comment']
      if (!isNotHaveSLA.includes(parsedData.extra.room.channel)) {
        this.$store.dispatch('inbox/getRoomSLA', { ids: [roomId] })
      }
    },
    async handleUnrespondedRoom(roomId) {
      const headers = {
        authorization: this.$auth.getToken('hub'),
      }
      const {
        data: { data },
      } = await this.$axios.get(
        `${process.env.HUB_SERVICE_URL}/api/core/v1/rooms/${roomId}`,
        { headers }
      )
      const index = _.findIndex(this.roomLists, { id: roomId })
      if (index < 0) {
        await this.$store.commit('inbox/UPDATE_UNSHIFT_ROOM', {
          room: data,
        })
      } else {
        // Update to room list
        await this.$store.commit('inbox/UPDATE_LIFT_UP_ROOM', {
          index,
          room: data,
        })
      }
    },
    getChannelName(channel) {
      switch (channel.toLowerCase()) {
        case 'wa':
          return 'WhatsApp'
        case 'wa_cloud':
          return 'WhatsApp'
        case 'whatsapp':
          return 'WhatsApp'
        case 'email':
          return 'E-mail'
        case 'qiscus':
          return 'Qiscus'
        case 'telegram':
          return 'Telegram'
        case 'fb':
          return 'Facebook'
        case 'livechat_dot_com':
          return 'Livechat.com'
        case 'ig':
          return 'Instagram'
        case 'ig_comment':
          return 'Instagram Comment'
        case 'twitter':
          return 'Twitter'
        case 'line':
          return 'Line Messenger'
        case 'web_chat':
          return 'Webchat'
        case 'app_chat':
          return 'Mobile chat'
        case 'qontak':
          return 'Qontak'
        case 'tokopedia_chat':
          return 'Tokopedia'
      }
    },
    updateTotalUnreadCountTabs(unread, channel) {
      // UPDATE TOTAL UNREAD COUNT TABS
      const totalUnread = unread || 0
      const payload = {
        type: channel === 'ig_comment' ? 'comment' : 'customer_service',
        value: totalUnread,
      }
      this.$store.commit('inbox/ADD_UNREAD_COUNT', payload)
    },
    async getMessageIfPartial(data) {
      return await this.$store.dispatch('conversation/getMessageById', {
        room_id: data.room_id,
        id: data.id,
      })
    },
    resetPoolingCheckpoint() {
      const minutesMultiply = 60000
      const dateNow = new Date()

      // Set checkpoint everytime user access inbox page
      this.$store.commit('inbox/SET_INBOX_POOLING_CHECKPOINT', new Date())

      // Set pooling next trigger for unassigned, rooms, and conversations
      this.$store.dispatch('inbox/setInboxPoolingTrigger', {
        type: 'unassigned',
        value: new Date(
          dateNow.getTime() +
            (this.inboxPoolingConfig.thresholds.unassigned || 1) *
              minutesMultiply
        ),
      })
      this.$store.dispatch('inbox/setInboxPoolingTrigger', {
        type: 'rooms',
        value: new Date(
          dateNow.getTime() +
            (this.inboxPoolingConfig.thresholds.rooms || 1) * minutesMultiply
        ),
      })
      this.$store.dispatch('inbox/setInboxPoolingTrigger', {
        type: 'conversations',
        value: new Date(
          dateNow.getTime() +
            (this.inboxPoolingConfig.thresholds.conversations || 1) *
              minutesMultiply
        ),
      })
    },
    trackNotificationLog(messageId) {
      this.$store.dispatch('inbox/trackNotificationLog', messageId)
    },
  },
}
